import { OrdersDetail, Wrapper } from "@royalcanin-fr-loyalty/ui-kit";
import { NetworkStatus } from "apollo-client";
import React from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { Layout } from "../components/Layout";
import { useCatalogPurchases } from "../lib/hooks/useCatalogPurchases";
import { isWebpSupported } from "../lib/utils";
import { withPage } from "../lib/withPage";

const Orders = () => {
  const {
    catalogPurchases,
    pageInfo,
    onLoadMore,
    loading,
    networkStatus,
  } = useCatalogPurchases();

  return (
    <Layout>
      <Wrapper>
        <Row center="xs">
          <Col xs={12} sm={12} md={12} lg={8}>
            <OrdersDetail
              orders={catalogPurchases.map((catalogPurchase) => ({
                ...catalogPurchase,
                productData: {
                  ...catalogPurchase.productData,
                  image: isWebpSupported()
                    ? catalogPurchase.productData &&
                      catalogPurchase.productData.thumbImage?.webp
                    : catalogPurchase.productData &&
                      catalogPurchase.productData.thumbImage?.src,
                },
              }))}
              onMoreClick={onLoadMore}
              moreLoading={networkStatus === NetworkStatus.fetchMore}
              pageInfo={pageInfo}
              loading={loading && catalogPurchases.length === 0}
            />
          </Col>
        </Row>
      </Wrapper>
    </Layout>
  );
};

export default withPage(Orders, {
  title: "Commandes",
});
